import { isValidCnpj, isValidCpf } from '@/helpers/validateDocument';
import * as yup from 'yup';

const typeString = yup.string();
const fieldRequired = typeString.required('Este campo é obrigatório!');

const handleDocumentValidationCase = (isSmb: boolean, isMgm?: boolean) => {
  if (isSmb) {
    return fieldRequired.test({
      name: 'isDocumentValid',
      skipAbsent: true,
      test(value, ctx) {
        if (!isValidCnpj(value) && !isValidCpf(value)) {
          return ctx.createError({
            message: 'Informe um CNPJ ou CPF válido',
          });
        }
        return true;
      },
    });
  }

  if (isMgm) {
    return fieldRequired.test({
      name: 'isDocumentValid',
      skipAbsent: true,
      test(value, ctx) {
        if (!isValidCnpj(value)) {
          return ctx.createError({
            message: 'Informe um CNPJ válido',
          });
        }
        return true;
      },
    });
  }
  return typeString;
};

export const schema = (isSmb: boolean, isMgm?: boolean) =>
  yup.object({
    name: fieldRequired.matches(
      /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/,
      'É necessário um nome válido!',
    ),
    email: fieldRequired.email('É necessário um e-mail válido!'),
    phone: fieldRequired.matches(
      /\(\d{2,}\) \d{4,}-\d{4}/g,
      'É necessário um telefone válido!',
    ),
    document: handleDocumentValidationCase(isSmb, isMgm),
    tpv: fieldRequired,
    channel: fieldRequired,
  });
